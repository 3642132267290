type NumberSign = "neutral" | "positive" | "negative";
type NumberSignSymbol = "" | "+" | "-";

export interface FormattedAmount {
  value: number;
  sign: NumberSign;
  symbol: NumberSignSymbol;
  integerValue: string;
  centValue: string;
}

export function roundAtXDecimals(floatToRound: number, numberOfDecimals: number = 2): number {
  return (
    Math.round(floatToRound * 10 ** numberOfDecimals + Number.EPSILON) / 10 ** numberOfDecimals
  );
}

function numberSign(value: number): NumberSign {
  if (value > 0) {
    return "positive";
  }

  if (value < 0) {
    return "negative";
  }

  return "neutral";
}

function signToSymbol(sign: NumberSign): NumberSignSymbol {
  switch (sign) {
    case "negative":
      return "-";
    case "positive":
      return "+";
    case "neutral":
      return "";
    default:
      return "";
  }
}

function numberSignSymbol(value: number): NumberSignSymbol {
  return signToSymbol(numberSign(value));
}

export function numberToFormattedAmount(
  value: number,
  options: {
    thousandSeparator?: string;
  } = {
    thousandSeparator: " ",
  },
): FormattedAmount {
  const { thousandSeparator } = options;
  const abs = Math.abs(value);
  let integer = Math.trunc(abs);
  let cents = Math.round(abs * 100 - integer * 100);

  while (cents >= 100) {
    cents = Math.max(0, cents - 100);
    integer++;
  }

  return {
    value,
    sign: numberSign(value),
    symbol: numberSignSymbol(value),
    integerValue: `${integer}`
      .split("")
      .reduceRight((acc, char, i, { length }) => {
        return `${char}${(length - (i + 1)) % 3 === 0 ? thousandSeparator : ""}${acc}`;
      }, "")
      .trim(),
    centValue: `${cents < 10 ? "0" : ""}${cents}`,
  };
}

export function numberToFormattedAmountString(
  value: number,
  options: {
    spacingCharacter: string;
    skipSymbols: NumberSignSymbol[];
  } = {
    spacingCharacter: " ",
    skipSymbols: [],
  },
): string {
  const { spacingCharacter, skipSymbols } = options;
  const { symbol, integerValue, centValue } = numberToFormattedAmount(value);
  const symbolPrefix = skipSymbols.includes(symbol) ? "" : `${symbol}${spacingCharacter}`;
  return `${symbolPrefix}${integerValue},${centValue}${spacingCharacter}€`;
}

export function getSumOf(data: number[]): number {
  return data.length > 0 ? data.reduce((a, b) => a + b, 0) : 0;
}

export function devideBy100(number: number | null): number | null {
  return isDefined(number) ? roundAtXDecimals(number / 100, 2) : null;
}

export function multiplyBy100(number: number | null): number {
  return isDefined(number) ? number * 100 : 0;
}
